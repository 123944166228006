import { makeStyles } from "@material-ui/styles";
import { mobileMediaStyle, tabMediaStyle } from "../../../components/helpers/ResponsiveStyleHelper";

export const useStyles = makeStyles({
  titleOne: {
    fontSize: "20px",
    paddingBottom: "25px",
    fontWeight: "500",
    fontFamily: "var(--primaryRegularFont)",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },

  requestsCount: {
    color: "#fb5b01",
    fontWeight: "bold",
    fontSize: "14px",
    fontStyle: "italic",
    width: "100%",
    textAlign: "left",
    marginLeft: "30px",
  },

  mainContainer: {
    padding: "30px 60px",
  },
  creatorImages: {
    display: "flex",
    gap: 24,
    marginLeft: 12,
  },

  findMoreText: {
    color: "#5b5757",
    padding: "10px",
    textAlign: "center",
    fontSize: "24px",
    fontFamily: "Roboto",
  },
  followingItem: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    gap: 15,
    marginBottom: "12px",
  },

  buttonWrapper: {
    marginLeft: "auto",
    height: "50px",
    display: "flex",
    gap: 30,
  },

  avatarWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "8px",
  },

  textWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: 5,
  },

  followingName: {
    fontSize: 16,
    fontFamily: "Roboto",
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "flex",
    fontWeight: 500,
    width: "100%",
  },

  followingUsername: {
    fontSize: 14,
    fontFamily: "Roboto",
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "flex",
    width: "100%",
  },
  Button: {
    fontFamily: "var(--secondaryHeaderFont)",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "23px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: "#fff",
    width: "137px",
    height: "45px",
    border: "2px solid #fff",
    borderRadius: "10px",
  },

  FollowBackButton: {
    fontFamily: "var(--secondaryHeaderFont)",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "23px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: "#fff",
    width: "137px",
    height: "45px",
    border: "2px solid #fff",
    borderRadius: "10px",
  },

  ...tabMediaStyle({
    mainContainer: {
      padding: "24px 36px 24px",
    },
    titleOne: {
      fontSize: "18px",
      lineHeight: "26px",
      display: "flex",
      alignItems: "center",
    },

    findMoreText: {
      fontSize: "10px",
    },
    creatorImages: {
      gap: 13,
    },
    buttonWrapper: {
      gap: 15,
    },
    Button: {
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "23px",
      width: "100px",
      height: "36px",
      border: "1px solid #fff",
    },
    FollowBackButton: {
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "23px",
      width: "100px",
      height: "36px",
      border: "1px solid #fff",
    },
    followingName: {
      fontSize: 14,
    },
    followingUsername: {
      fontSize: 10,
    },
  }),
  ...mobileMediaStyle({
    mainContainer: {
      padding: "12px 18px 12px",
    },
    titleOne: {
      fontSize: "16px",
      lineHeight: "20px",
      display: "flex",
      "& > svg": {
        height: "20px",
        paddingRight: "5px",
      },
    },

    creatorImages: {
      gap: 12,
    },
    findMoreText: {
      fontSize: "10px",
    },

    followingName: {
      fontSize: 12,
    },

    followingUsername: {
      fontSize: 10,
    },
    buttonWrapper: {
      gap: 15,
    },
    Button: {
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "23px",
      width: "85px",
      height: "36px",
      border: "1px solid #fff",
    },
    FollowBackButton: {
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "23px",
      width: "85px",
      height: "36px",
      border: "1px solid #fff",
    },
  }),
});

// import "react-loading-skeleton/dist/skeleton.css";
import "./boxStyle.css";

const Clothes = {
    Activewear: true,
    Beachwear: true,
    Denim: true,
    Dresses: true,
    Loungewear: true,
    Miscellaneous: true,
    Home: true,
    Jumpsuit: true,

    Shirts: true,
    Tops: true,
    Pants: true,
    Shorts: true,
    Skirts: true,
};

const Accessories = { Accessories: true, Beauty: true };
const Footwear = { Shoes: true };
const Outerwear = { Outerwear: true };
export default function CurationBoxes(props) {

    const { curations } = props;
    const clothesList = curations?.filter((item) => Clothes[item?.mainCategory]);
    const accessoriesList = curations?.filter((item) => Accessories[item?.mainCategory]);
    const footwearList = curations?.filter((item) => Footwear[item?.mainCategory]);
    const outerwearList = curations?.filter((item) => Outerwear[item?.mainCategory]);

    const groupClothList = clothesList?.sort((a, b) => {
        if (["tops", "shirts"].includes(a?.mainCategory?.toLowerCase()) && ["skirts", "shorts", "pants", "denim"].includes(b?.mainCategory?.toLowerCase())) {
            return -1;
        }
        if (["skirts", "shorts", "pants", "denim"].includes(a?.mainCategory?.toLowerCase()) && ["tops", "shirts"].includes(b?.mainCategory?.toLowerCase())) {
            return 1;
        }
        return 0;
    });

    return (
        <>
            <div className={`common-div-wrapper-box ${(outerwearList?.length <= 0 && accessoriesList?.length <= 0 && footwearList?.length <= 0) && "custom-block"}`}>
                <div className={`groupClothList-${groupClothList?.length}`}>
                    {groupClothList?.map((item, index) => (
                        <div key={`src-${index}`} className="img-box">
                            <img
                                draggable={false}
                                src={item.src}
                            />
                        </div>
                    ))}
                </div>
                <div
                    style={{
                        display: "flex",
                        rowGap: "0px",
                        flexDirection: "column",
                        height: "100%",
                        justifyContent: "center",
                    }}
                    className={`Right-block-div ${(outerwearList?.length <= 0 && accessoriesList?.length <= 0 && footwearList?.length <= 0) && "d-none"} ${(outerwearList?.length > 0 && accessoriesList?.length > 0 && footwearList?.length > 0) && "listing-div"} 
                    ${(outerwearList?.length > 0 && accessoriesList?.length > 0) && "outwear-accessory-block"}
                        ${(outerwearList?.length > 0 && footwearList.length > 0) && "outwear-footwear-block"}
                    `}
                >
                    <div className={`outerwearList-${outerwearList?.length}`}>
                        {outerwearList?.map((item, index) => (
                            <div key={`src-${index}`} className={`img-box ${(accessoriesList?.length <= 0 && footwearList?.length <= 0) && "outerwearBlock"}`}>
                                <img
                                    draggable={false}
                                    src={item.src}
                                />
                            </div>
                        ))}
                    </div>
                    <div className={`accessoriesList-${accessoriesList?.length}`}>
                        {accessoriesList?.map((item, index) => (
                            <div key={`src-${index}`} className={`img-box ${(outerwearList?.length <= 0 && footwearList?.length <= 0) && "accessoriesBlock"} `}>
                                <img
                                    draggable={false}
                                    src={item.src}

                                />
                            </div>
                        ))}
                    </div>
                    <div className={`footwearList-${footwearList?.length}`}>
                        {footwearList?.map((item, index) => (
                            <div key={`src-${index}`} className={`img-box ${(outerwearList?.length <= 0 && accessoriesList?.length <= 0) && "footwearBlock"}`}>
                                <img
                                    draggable={false}
                                    src={item.src}

                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </>
    );
}
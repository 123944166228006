import IconComponent from "../../../../components/fields/icon";
import SubCurations from "./SubCurations";

export default function AccessoriesTag({
  linearCategories,
  accessoriesList,
  removeCurationItem,
  parentIndex,
  classes,
  OutWearLength,
  onDropCurationContainer,
  setIsAccessory,
  showCrossIcon,
  setCurrentIndex,
}) {
  const subCategories = Object.keys(linearCategories).filter(
    (subItem) =>
      linearCategories[subItem] === "Accessories" ||
      linearCategories[subItem] === "Beauty" ||
      linearCategories[subItem] === "Handbags",
  );

  const GroupList3 = [];
  for (let element of subCategories) {
    const arr2 = accessoriesList.filter(
      (item) => item.category.toLowerCase() === element.toLowerCase(),
    );
    GroupList3.push(...arr2); // Spread operator to add elements individually
  }

  const GroupList = [GroupList3]?.filter((item) => item?.length);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "grid",
      }}
      className={`GroupList-wrapper${GroupList.length} ${OutWearLength <= 0 && "border-0"}`}
    >
      {GroupList.map((subItemList, itemIndex) => (
        <SubCurations
          key={itemIndex}
          subCategories={subCategories}
          subItemList={subItemList}
          removeCurationItem={removeCurationItem}
          parentIndex={parentIndex}
          classes={classes}
          setIsAccessory={setIsAccessory}
          onDropCurationContainer={onDropCurationContainer}
          showCrossIcon={showCrossIcon}
          setCurrentIndex={setCurrentIndex}
        />
      ))}
    </div>
  );
}

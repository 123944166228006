import { makeStyles } from "@material-ui/styles";
import { mobileMediaStyle, tabMediaStyle } from "../../../components/helpers/ResponsiveStyleHelper";

export const useStyles = makeStyles({
  mainContainer: {
    padding: "30px 60px",
  },

  titleOne: {
    fontSize: "20px",
    lineHeight: "40px",
    fontWeight: "500",
    fontFamily: "var(--primaryRegularFont)",
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },

  creators: {
    display: "flex",
    flexWrap: "wrap",
    gap: "15px",
    justifyContent: "center",
  },

  creatorItem: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    width: "auto",
    flex: "1 1 calc(20% - 15px)",
    marginBottom: "12px",
    maxWidth: "200px",
    gap: 12,
  },

  avatarWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  avatar: {
    width: 90,
    height: 90,
  },

  creatorName: {
    textAlign: "center",
    marginTop: 6,
    fontSize: 16,
    fontFamily: "Roboto",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "140px",
    minWidth: "135px",
  },
  verticalCreatorName: {
    textAlign: "justify",
    marginTop: 6,
    fontSize: 16,
    fontFamily: "Roboto",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "140px",
    minWidth: "135px",
  },

  followingUsername: {
    fontSize: 14,
    fontFamily: "Roboto",
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "flex",
    width: "100%",
  },

  followButton: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "16px",
    background: "#335b46",
    color: "#fff",
    width: "120px",
    height: "45px",
    border: "1px solid #fff",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
  },
  searchBarContainer: {
    padding: "20px 60px",
    backgroundColor: "#b98d6f",
  },

  searchBar: {
    width: "90%",
    maxWidth: "400px",
  },

  searchInput: {
    backgroundColor: "#e5e5e5",
    borderRadius: "12px",
  },

  gridLayout: {
    display: "flex",
    flexWrap: "wrap",
    gap: "25px",
  },
  verticalLayout: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  searchCreatorItem: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    width: "auto",
    marginBottom: "12px",
    gap: 15,
  },

  textWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: 5,
  },

  ...tabMediaStyle({
    mainContainer: {
      padding: "24px 36px",
    },
    titleOne: {
      fontSize: "18px",
      lineHeight: "30px",
    },
    avatar: {
      width: 50,
      height: 50,
    },
    followButton: {
      fontSize: "14px",
      width: "90px",
      height: "36px",
    },
    creatorName: {
      fontSize: "14px",
    },
    verticalCreatorName: {
      fontSize: "14px",
    },

    gridLayout: {
      gap: "20px",
    },
    followingUsername: {
      fontSize: 12,
    },
  }),

  ...mobileMediaStyle({
    gridLayout: {
      gap: "15px",
    },
    mainContainer: {
      padding: "12px 18px",
    },
    creatorName: {
      fontSize: "12px",
    },
    verticalCreatorName: {
      fontSize: "12px",
    },
    titleOne: {
      fontSize: "16px",
      lineHeight: "24px",
    },
    creatorItem: {
      flex: "1 1 calc(50% - 10px)",
    },
    avatar: {
      width: 36,
      height: 36,
    },
    followButton: {
      fontSize: "12px",
      width: "80px",
      height: "30px",
    },
    followingUsername: {
      fontSize: 10,
      fontFamily: "Roboto",
      whiteSpace: "nowrap",
      overflow: "hidden",
      display: "flex",
      width: "100%",
    },
  }),

  // "@media (max-width: 912px)": {
  //   creators: {
  //     gap: "10px",
  //   },
  //   creatorItem: {
  //     flex: "1 1 calc(25% - 10px)",
  //   },
  //   avatar: {
  //     width: 48,
  //     height: 48,
  //   },
  //   followButton: {
  //     fontSize: "14px",
  //     width: "100px",
  //     height: "36px",
  //   },
  //   titleOne: {
  //     fontSize: "22px",
  //     lineHeight: "28px",
  //   },
  // },
});

import ActivewearNeutral from "./activewear_neutral.png";
import AccessoriesNeutral from "./Accessories_Non_Binary.png";
import BeautyNeutral from "./Beauty.jpeg";
import BeachwearNeutral from "./Beachwear.jpg";
import DenimNeutral from "./Denim.jpg";
import DressesNeutral from "./Dress_Women.jpg";
import LoungewearNeutral from "./Loungewear_Non_Binary.png";
import MiscellaneousNeutral from "./Misc.jpg";
import OuterwearNeutral from "./Outerwear2.jpg";
import PantsNeutral from "./pants_neutral.png";
import ShirtsNeutral from "./Shirt.jpg";
import ShoesNeutral from "./Shoes_Non_Binary.png";
import ShortsNeutral from "./Shorts_Non_Binary.png";
import SkirtsNeutral from "./Skirt_Non_Binary.png";
import SweatersNeutral from "./sweaters_neutral.png";
import TopsNeutral from "./suit_non_binary.jpg";
import HangerNeutral from "./hanger_neutral.png";
import HomeNeutral from "./Handbag_Non_Binary.png";

export default {
  ActivewearNeutral,
  AccessoriesNeutral,
  BeachwearNeutral,
  BeautyNeutral,
  DenimNeutral,
  DressesNeutral,
  LoungewearNeutral,
  MiscellaneousNeutral,
  OuterwearNeutral,
  PantsNeutral,
  ShirtsNeutral,
  ShoesNeutral,
  ShortsNeutral,
  SkirtsNeutral,
  SweatersNeutral,
  TopsNeutral,
  HangerNeutral,
  HomeNeutral,
};

import { Avatar } from "@mui/material";
import { useStyles } from "./style";
import { Button } from "../../components/dashboard";
import CurationGroup from "./CurationGroup";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserProfileData } from "../../apollo/operations/user";
import { getCurationsList } from "../../apollo/operations/curation";
import { getItems, getItemsCount } from "../../apollo/operations/items";
import { useLazyQuery } from "@apollo/client";
import { openToast } from "../../components/toast";
import { mobileMaxWidth, mediaQueries } from "../../components/helpers/ResponsiveStyleHelper";
import {
  getFollowersLists,
  getFollowRequests,
  getFollowingLists,
} from "../../apollo/operations/followRequest";
import ProfileCurationLists from "../../components/ProfileCurationLists";
import { ReactComponent as Arrow } from "../../assets/svg/arrowRightLinear.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

export default function Profile() {
  const pageSize = 20;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [getProfile, { data: userProfile }] = useLazyQuery(getUserProfileData);
  const [getCurations, { data: listOfCuration }] = useLazyQuery(getCurationsList);
  const [getSaleItems, { data: saleItemList }] = useLazyQuery(getItems);
  const [getSaleItemsCount, { data: saleItemCount }] = useLazyQuery(getItemsCount);

  
  const [getFollowers, { data: followersLists }] = useLazyQuery(getFollowersLists);
  const [getRequests, { data: FollowRequests }] = useLazyQuery(getFollowRequests);
  const [getFollowings, { data: followingLists }] = useLazyQuery(getFollowingLists);

  const navigate = useNavigate();
  const classes = useStyles();
  const [saleItems, setSaleItems] = useState([]);
  const [user, setUser] = useState({});
  const [curationList, setCurationList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  const toggleItems = () => {
    setIsOpen(!isOpen);
  };

  const itemsPerPage = 10;

  const followings = [
    ...(followingLists?.getFollowingLists?.data || []),
    { profileUrl: "", name: "FindMore" },
  ];

  useEffect(() => {
    getProfile();
    getCurations();
    getFollowers();
    getRequests();
    getFollowings();
    getSaleItems({ variables: { category: [], limit: pageSize, offset: page * pageSize } });
    getSaleItemsCount()
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getSaleItems({ variables: { category: [], limit: pageSize, offset: page * pageSize } });
  }, [page])

  useEffect(() => {
    if (saleItemList?.getItemsFromCloset?.length) {
      setSaleItems(saleItemList.getItemsFromCloset);
    }
  }, [saleItemList]);
  useEffect(() => {
    if (saleItemCount?.getItemsFromClosetCount?.count) {
      setTotalItemsCount(saleItemCount?.getItemsFromClosetCount?.count);
    }
  }, [saleItemCount]);


  useEffect(() => {
    if (userProfile && userProfile.getUserDetails) {
      if (
        userProfile.getUserDetails.statusCode !== "200" ||
        userProfile.getUserDetails.message !== "Fetched Successfully"
      ) {
        openToast("error", "Failed", "Try again later");
        return;
      }
      setUser(JSON.parse(JSON.stringify(userProfile.getUserDetails.data)));
    }
  }, [userProfile]);

  useEffect(() => {
    if (currentPage > Math.ceil(curationList.length / itemsPerPage)) {
      setCurrentPage(1);
    }
  }, [curationList]);

  const paginatedCurationList = curationList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  const handleOffSet = (direction) => {
    if (direction === "back" && currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
    if (direction === "next" && currentPage < Math.ceil(curationList.length / itemsPerPage)) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (listOfCuration?.getCurationsList?.data && listOfCuration?.getCurationsList?.data?.length) {
      // let curationData = {};
      let list = listOfCuration.getCurationsList.data.filter(
        (item) => item.isAdded && item.curationList.length,
      );
      // if (list.length) {
      //   for (let item of list) {
      //     if (curationData[item?.curationLook]) {
      //       curationData[item?.curationLook]?.push(item);
      //     } else {
      //       curationData[item?.curationLook] = [item];
      //     }
      //   }
      // }
      setCurationList(list);
    }
  }, [listOfCuration]);

  const handleOffSetItemList = (value) => {
    if (value === "back") {
      if (page !== 0) setPage(page - 1);
    } else {
      if (totalItemsCount > (page + 1) * pageSize) setPage(page + 1);
    }
  };

  const ImageContainer = styled.div`
    height: 157px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;

    @media (${mediaQueries.mobile}) {
      height: 125px;
    }
  `;

  const SaleItem = styled.div`
    width: 135px;

    & > p {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.36px;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    @media (${mediaQueries.mobile}) {
      width: 111px;

      & > p {
        font-size: 10px;
        letter-spacing: 0.3px;
      }
    }
  `;

  return (
    <>
      <div className={classes.root}>
        <div className={classes.profile}>
          <Avatar
            alt={user?.firstName}
            src={user?.profilePicUrl}
            sx={{
              width: windowWidth <= mobileMaxWidth ? 92 : 164,
              height: windowWidth <= mobileMaxWidth ? 92 : 164,
            }}
          />
          <div className={classes.profileDetails}>
            <h1>
              {user?.firstName} {user?.lastName}
            </h1>
            <p className={classes.email}>{user?.email}</p>
            <Button
              buttontype="primary"
              className={classes.editBtn}
              onClick={() => navigate("/settings/account")}
            >
              Edit Profile
            </Button>
          </div>
        </div>
        <div className={classes.followersDetails}>
          <div className={classes.subFollow} onClick={() => navigate("/followme/following")}>
            <h1>{followingLists?.getFollowingLists?.data?.length || 0}</h1>Following
          </div>
          <div className={classes.subFollow} onClick={() => navigate("/followme/followers")}>
            <h1>{followersLists?.getFollowersLists?.data.length || 0}</h1>Followers
          </div>
          <div className={classes.subFollow} onClick={() => navigate("/followme/follow_requests")}>
            <h1>{FollowRequests?.getFollowRequests?.data.length}</h1>Follow Requests
          </div>
        </div>
        <div className={classes.about}>{user?.bio}</div>
        <p className={classes.following}>Following</p>
        <div className={classes.followingImages}>
          {followings?.map((item, i) => (
            <div key={i}>
              <Avatar
                alt="Remy Sharp"
                src={item?.profileUrl}
                sx={{
                  width: window.innerWidth <= mobileMaxWidth ? 36 : 72,
                  height: window.innerWidth <= mobileMaxWidth ? 36 : 72,
                  backgroundColor: item?.name === "FindMore" ? "#335b46" : "",
                }}
                onClick={() => {
                  if (item?.name === "FindMore") {
                    navigate("/followme/creators");
                  } else {
                    navigate(`/followme/profile/${item?.username}`);
                  }
                }}
              >
                {item?.name === "FindMore" && (
                  <span
                    // onClick={() => navigate("/followme/creators")}
                    className={classes.findMoreText}
                  >
                    Find More
                  </span>
                )}
              </Avatar>
              {item?.name !== "FindMore" && <p className={classes.followingName}>{item?.name}</p>}
            </div>
          ))}
        </div>

        {paginatedCurationList?.length > 0 && (
          <>
            <p className={classes.following}>Curated by Me</p>
            <div className={classes.paginationControls}>
              <div className={classes.paggination}>
                <p>{`${
                  curationList.length ? (currentPage - 1) * itemsPerPage + 1 : currentPage
                } - ${
                  currentPage * itemsPerPage > curationList.length
                    ? curationList.length
                    : currentPage * itemsPerPage
                } of ${curationList.length}`}</p>
                <Arrow className={classes.arrowLeft} onClick={() => handleOffSet("back")} />
                <Arrow className={classes.arrowRight} onClick={() => handleOffSet("next")} />
              </div>
            </div>
            <div className={`${classes.curationContainer} ${classes.favCurationContainer}`}>
              {paginatedCurationList?.map(
                (outfits, index) =>
                  outfits.curation.length > 0 && (
                    <ProfileCurationLists outfits={outfits} key={index} index={index} />
                  ),
              )}
            </div>
          </>
        )}

        {/* {!!saleItems.length && (
          <>
            <p className={classes.myItems}>My Items</p>
            <div className={classes.itemsConatiner}>
              {saleItems?.map((saleItem) => (
                <SaleItem key={saleItem.id}>
                  <ImageContainer>
                    <img className={classes.outfitImage} src={saleItem.src} />
                  </ImageContainer>
                  <p>{saleItem.itemName}</p>
                </SaleItem>
              ))}
            </div>
          </>
        )} */}

        <div>
          <p
            className={classes.myItems}
            onClick={toggleItems}
            style={{ cursor: "pointer", alignItems: "center", display: "flex" }}
          >
            My Items {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </p>
          {isOpen && (
            <div>
              <div className={classes.paggination}>
                <p>{`${totalItemsCount ? page * pageSize + 1 : page} - ${
                  (page + 1) * pageSize > totalItemsCount ? totalItemsCount : (page + 1) * pageSize
                } of ${totalItemsCount}`}</p>
                <Arrow className={classes.arrowLeft} onClick={() => handleOffSetItemList("back")} />
                <Arrow className={classes.arrowRight} onClick={() => handleOffSetItemList("next")} />
              </div>
              <div className={classes.itemsConatiner}>
                {saleItems?.map((saleItem) => (
                  <SaleItem key={saleItem.id}>
                    <ImageContainer>
                      <img className={classes.outfitImage} src={saleItem.src} />
                    </ImageContainer>
                    <p>{saleItem.itemName}</p>
                  </SaleItem>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

import { useLazyQuery, useMutation } from "@apollo/client";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addItemByUploadImage,
  getURLProduct,
  uploadImage,
  addItemToCloset,
} from "../../../apollo/operations/items";
import { ReactComponent as LeftArrow } from "../../../assets/svg/arrowLeft.svg";
import { getMainCategory } from "../../../components/helpers/categoryHelper";
import { openToast } from "../../../components/toast";
import DetailSection from "./DetailSection";
import { useStyles } from "./styles";
import UploadYourImage from "./UploadYourImage";
import Loader from "../../../components/loaderScreen";
import Slider from "react-slick";
import IconComponent from "../../../components/fields/icon";
import ModalPortal from "../../../containers/common/modalPortal";
import UploadVideoTour from "./UploadVideoTour";

export default function UploadFile() {
  const classes = useStyles();
  const [image, setImage] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [imageList, setImageList] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [productUrl, setProductUrl] = useState({ oldValue: "", currentValue: "" });
  const [addButtonEnable, setAddButtonEnable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [itemDetails, setItemDetails] = useState({
    brand: "",
    store: "",
    itemName: "",
    category: "",
    color: "",
    size: "",
    tags: "",
    productLink: "",
    occasions: ""
  });
  const [addToCloset, { data: addItemToClosetData }] = useMutation(addItemByUploadImage);
  const [Upload, { data: uploadResponse }] = useLazyQuery(uploadImage);
  const [
    getURLProductDetails,
    { data: urlProductDetails, loading: urlProductLoading, errors: urlProductsError },
  ] = useLazyQuery(getURLProduct);
  const [addItems, { data: addItemsData }] = useMutation(addItemToCloset);
  const categoriesList = useSelector((state) => state.category.category);

  const [settings] = useState({
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: true,
    variableWidth: true,
    className: classes.sliderContainer,
  });

  const [avoidRenderFlag, setAvoidRenderFlag] = useState(false);
  const [openVideoTour, setOpenVideoTour] = useState(null);

  function handleVideoModal() {
    setOpenVideoTour(!openVideoTour);
  }

  useEffect(() => {
    // enable or disable Add button
    if (image && itemDetails.category && itemDetails.occasions) {
      if (!addButtonEnable) {
        setAddButtonEnable(true);
      }
    } else if (addButtonEnable) {
      setAddButtonEnable(false);
    }
  }, [image, itemDetails]);

  useEffect(() => {
    if (uploadResponse?.getPresignedUploadURL?.url && avoidRenderFlag) {
      uploadImageUsingPresignedURl(uploadResponse);
    }
    setAvoidRenderFlag(false);
  }, [uploadResponse]);

  useEffect(() => {
    if (urlProductDetails && urlProductDetails.getProductFromUrl.statusCode === "200") {
      if (urlProductDetails.getProductFromUrl.data) {
        const data = urlProductDetails.getProductFromUrl.data;
        if (data.images) {
          if (data.images.length > 0) setImageList(data.images);
          else openToast("error", "Failed", "Failed to fetch product images, try again later");
        }
      }
    }
  }, [urlProductDetails]);

  useEffect(() => {
    if (urlProductsError) {
      if (
        urlProductsError[0].message.includes("net::ERR_NAME_NOT_RESOLVED") ||
        urlProductsError[0].message.includes("got invalid value")
      ) {
        openToast("error", "Invalid url", "Please enter a valid url");
      } else openToast("error", "Failed", "Failed to fetch product images, try again later");
    }
  }, [urlProductsError]);

  useEffect(() => {
    if (addItemToClosetData) {
      if (addItemToClosetData.addItemToClosetByImageUpload?.statusCode === "200") {
        openToast("success", "Successful!", "Item added");

        // find main category before redirecting
        const mainCategory = getMainCategory(categoriesList, itemDetails.category);
        navigate(`/dashboard/category/${mainCategory}`);
      } else {
        setIsLoading(false);
        openToast("error", "Failed!", "Try Again");
      }
    }
  }, [addItemToClosetData]);

  useEffect(() => {
    if (addItemsData) {
      if (addItemsData.addItemToCloset?.statusCode === "200") {
        openToast("success", "Successful!", "Item added");
        // find main category before redirecting
        const mainCategory = getMainCategory(categoriesList, itemDetails.category);
        navigate(`/dashboard/category/${mainCategory}`);
      } else {
        setIsLoading(false);
        openToast("error", "Failed!", "Try Again");
      }
    }
  }, [addItemsData]);

  async function uploadImageUsingPresignedURl(uploadResponse) {
    var parts = imageFile.name.split(".");
    const fileType = parts[parts.length - 1];
    const res = await fetch(uploadResponse.getPresignedUploadURL.url, {
      method: "PUT",
      headers: {
        "Content-Type": fileType,
      },
      body: imageFile,
    });
    if (res.status === 200) {
      const key = uploadResponse.getPresignedUploadURL.key;
      addToCloset({
        variables: {
          addImageItem: {
            brand: itemDetails.brand?.trim(),
            store: itemDetails.store?.trim(),
            itemName: itemDetails.itemName?.trim(),
            category: itemDetails.category,
            color: itemDetails.color?.trim(),
            size: itemDetails.size?.trim(),
            price: itemDetails.price?.trim(),
            datePurchased: itemDetails.datePurchased || null,
            tags: itemDetails.tags?.trim(),
            key: key,
            productUrl: itemDetails.productLink || null,
            occasions: itemDetails.occasions || null
          },
        },
      });
    } else {
      setIsLoading(false);
      openToast("error", "Failed!", "Try again");
    }
  }

  function addItem() {
    if (addButtonEnable) {
      setIsLoading(true);
      if (image.split(":")[0] !== "blob") {
        addItems({
          variables: {
            addItems: {
              items: [
                {
                  brand: itemDetails.brand?.trim(),
                  store: itemDetails.store?.trim(),
                  itemName: itemDetails.itemName?.trim(),
                  category: itemDetails.category,
                  color: itemDetails.color?.trim(),
                  size: itemDetails.size?.trim(),
                  tags: itemDetails.tags?.trim(),
                  price: itemDetails.price?.trim(),
                  datePurchased: itemDetails.datePurchased,
                  src: image.substring(0, 5) === "https" ? image : "https:" + image,
                  productUrl: itemDetails.productLink || null,
                  productStoreUrl: itemDetails.productLink || null,
                  occasions: itemDetails.occasions || null
                },
              ],
            },
          },
        });
      } else if (image) {
        // getting presigned upload url
        const fileName = imageFile.name;
        var parts = fileName.split(".");
        const fileType = parts[parts.length - 1];
        Upload({ variables: { file: { fileName: fileName, fileType: fileType } } });
        setAvoidRenderFlag(true);
      }
    }
  }

  function imageUrlChange(e) {
    setImageUrl(e.target.value);
    setProductUrl({ ...productUrl, currentValue: e.target.value });
  }

  function searchURL() {
    if (productUrl.currentValue !== productUrl.oldValue) {
      if (imageList.length > 0) {
        setImageList([]);
      }
      // call api to fetch product details
      setImage("");
      setProductUrl((prevState) => ({ ...prevState, oldValue: prevState.currentValue }));
      getURLProductDetails({ variables: { url: productUrl.currentValue } });
    }
  }

  function searchImageURL() {
    if (imageUrl) {
      let url = imageUrl.toLowerCase();
      const regex = /.[.]jpg|.[.]jpeg|.[.]avif|.[.]apng|.[.]png|.[.]webp/gm;
      if (url.match(regex)) {
        setImage(imageUrl);
        if (imageList.length > 0) {
          setImageList([]);
        }
      } else {
        setItemDetails({ ...itemDetails, productLink: imageUrl });
        searchURL();
      }
    }
  }

  function handleImageClick(image) {
    setImage(image);
    if (imageFile) {
      setImageFile("");
    }
  }

  // method to handle select image from file manager
  function handleSelectImage(e) {
    const { files } = e.target;
    if (files && files.length) {
      setImageFile(files[0]);
      setImage(URL.createObjectURL(files[0]));
      if (imageUrl !== "") {
        setImageUrl("");
      }
      if (productUrl) {
        setProductUrl({ oldValue: "", currentValue: "" });
      }
      if (imageList.length > 0) {
        setImageList([]);
      }
    }
  }

  function handleKeyPress(event, type) {
    if (event.keyCode === 13) {
      if (type === "imageUrl") {
        searchImageURL();
      }
    }
  }

  return (
    <div className={classes.uploadFileBody}>
      <div className={classes.uploadImageBody}>
        <div className={classes.uploadImageSection}>
          <div className={classes.uploadFileHeader}>Upload File</div>
          <UploadYourImage
            image={image}
            handleSelectImage={handleSelectImage}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              setImage("");
              openToast("error", "Error", "Not a valid link");
            }}
          />
          <div className={classes.text}>{"OR"}</div>
          <div style={{ display: "flex" }}>
            <div className={classes.uploadUrlWrapper}>
              <div className={classes.uploadUrl}>
                <input
                  className={classes.inputUrl}
                  type="url"
                  placeholder="Site/Image URL"
                  value={imageUrl}
                  onKeyDown={(e) => handleKeyPress(e, "imageUrl")}
                  onChange={imageUrlChange}
                />
              </div>
              <div className={classes.goButton} onClick={searchImageURL}>
                <LeftArrow />
              </div>
            </div>
            <div
              className={`${classes.infoContainer}`}
              style={{ paddingLeft: "5px" }}
              onClick={handleVideoModal}
            >
              <a data-tooltip-id="info-tooltip" data-tooltip-content="Click For Details">
                <IconComponent iconTitle={"InfoIcon"} />
              </a>
            </div>
          </div>
        </div>

        {imageList.length > 0 && (
          <div className={classes.imagesOptions}>
            <span className={classes.labelText}>Choose image</span>
            <div className={classes.sliderwrapper}>
              <Slider {...settings}>
                {imageList.map((iLink, index) => (
                  <div
                    key={index}
                    className={classes.imageFrame}
                    onClick={() => handleImageClick(iLink)}
                  >
                    <img src={iLink} className={classes.images} />
                  </div>
                ))}
              </Slider>
            </div>
            <div className={classes.parentImagesContainer}>
              <div className={classes.imagesContainer}>
                {imageList.map((iLink, index) => (
                  <div
                    key={index}
                    className={`${classes.imageFrame} ${
                      iLink === image ? classes.selectedFrame : classes.normalFrame
                    }`}
                    onClick={() => handleImageClick(iLink)}
                  >
                    <img src={iLink} className={classes.images} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <DetailSection
        itemDetails={itemDetails}
        setItemDetails={setItemDetails}
        categoriesList={categoriesList}
      />
      <div className={classes.buttonContainer}>
        <div
          className={`${classes.button} ${
            addButtonEnable ? classes.enabledButton : classes.disabledButton
          }`}
          onClick={addItem}
        >
          Add
        </div>
      </div>
      {(isLoading || urlProductLoading) && <Loader />}
      {openVideoTour && (
        <ModalPortal>
          <UploadVideoTour modalResponse={handleVideoModal} title={"Upload Image Url"} />
        </ModalPortal>
      )}
    </div>
  );
}

import { makeStyles } from "@material-ui/styles";
import { mobileMediaStyle, tabMediaStyle } from "../../../components/helpers/ResponsiveStyleHelper";

export const useStyles = makeStyles({
  mainContainer: {
    padding: "30px 60px",
    fontFamily: "var(--primaryRegularFont)",
  },

  Button: {
    fontFamily: "var(--secondaryHeaderFont)",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "23px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: "#fff",
    width: "140px",
    height: "45px",
    border: "2px solid #fff",
    borderRadius: "10px",
    marginTop: "20px",
  },
  paggination: {
    padding: "10px 0 10px",
    justifyContent: "right",
    display: "flex",
    fontSize: ".75rem",
    letterSpacing: "normal",
    color: "#5e5e5e",
  },
  arrowLeft: {
    padding: "0px 5px",
    rotate: "90deg",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#D9D9D9",
    },
  },
  arrowRight: {
    padding: "0px 5px",
    rotate: "-90deg",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#D9D9D9",
    },
  },

  titleOne: {
    fontSize: "20px",
    lineHeight: "40px",
    fontWeight: "500",
    fontFamily: "var(--primaryRegularFont)",
    margin: "6px 0 20px 0",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  profile: {
    marginTop: "40px",
    display: "flex",
    gap: 40,
    alignItems: "center",
  },
  profileDetails: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  email: {
    fontSize: 20,
  },
  editBtn: {
    marginTop: 50,
  },
  followersDetails: {
    marginTop: 68,
    display: "flex",
    gap: 40,
  },
  subFollow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
    fontSize: 18,
    letterSpacing: 0.72,
    "& h1": {
      fontSize: 20,
    },
  },
  following: {
    margin: "12px 0 6px 0",
    fontWeight: 600,
    lineHeight: "36px",
  },
  about: {
    marginTop: 12,
    lineHeight: "26px",
    whiteSpace: "pre-line",
  },
  followingImages: {
    display: "flex",
    gap: 24,
    cursor: "pointer",
  },
  followingName: {
    textAlign: "center",
    marginTop: 6,
    fontSize: 12,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "72px",
  },
  curationGroupContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: 40,
  },

  findMoreText: {
    color: "#fff",
    padding: "10px",
    textAlign: "center",
    fontSize: "16px",
    fontFamily: "Roboto",
    lineHeight: "25px",
  },
  curationContainer: {
    paddingTop: "24px",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))",
    gap: "30px 15px",
  },

  ...tabMediaStyle({
    mainContainer: {
      padding: "24px 36px 24px",
    },
    subFollow: {
      fontSize: 14,
      gap: 6,
      letterSpacing: 0.48,
      "& h1": {
        fontSize: 16,
      },
    },
    Button: {
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "23px",
      width: "105px",
      height: "36px",
      border: "1px solid #fff",
      marginTop: "10px",
    },
    paggination: {
      width: "97%",
    },
  }),

  ...mobileMediaStyle({
    mainContainer: {
      padding: "12px 18px 12px",
    },
    paggination: {
      padding: "12px 0 16px",
      width: "86%",
    },
    Button: {
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "23px",
      width: "95px",
      height: "36px",
      border: "1px solid #fff",
    },
    profile: {
      gap: 30,
      marginTop: 18,
    },
    profileDetails: {
      gap: 0,
      "& h1": {
        fontSize: 16,
        letterSpacing: 0.48,
      },
    },

    email: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 0.36,
    },
    followersDetails: {
      marginTop: 31,
      gap: 24,
    },
    subFollow: {
      fontSize: 12,
      gap: 6,
      letterSpacing: 0.48,
      "& h1": {
        fontSize: 14,
      },
    },
    about: {
      lineHeight: "18px",
      fontSize: 10,
    },
    following: {
      fontSize: 12,
      lineHeight: "unset",
    },
    followingName: {
      fontSize: 8,
    },
    followingImages: {
      gap: 12,
    },
    curationGroupContainer: {
      gap: "12px 18px",
      "& > div": {
        height: 170,
        width: 170,
      },
    },
    curationGroupName: {
      fontSize: 10,
      marginTop: 6,
    },

    followMeText: {
      fontSize: "7px",
    },
    curationContainer: {
      paddingTop: "15px",
      gridTemplateColumns: "repeat(auto-fill, minmax(180px, 1fr))",
      gap: "12px 15px",
    },
  }),
});

import { useState, useEffect, useCallback, useMemo } from "react";
import { useStyles } from "./styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Avatar, TextField, InputAdornment } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { mobileMaxWidth } from "../../../components/helpers/ResponsiveStyleHelper";
import IconComponent from "../../../components/fields/icon";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  getTrendingCreators,
  sendFollowRequest,
  unFollowCreators,
  getCreatorsQuery,
} from "../../../apollo/operations/followRequest";
import ModalPortal from "../../../containers/common/modalPortal";
import Modal from "../../../containers/common/modalPortal/modal";

export default function Creators() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [followedCreators, setFollowedCreators] = useState({});
  const [showUnfollowPopup, setShowUnfollowPopup] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const [searchCreatorsQuery, { data: searchedCreators }] = useLazyQuery(getCreatorsQuery);
  const [getCreators, { data: trendingCreators }] = useLazyQuery(getTrendingCreators);
  const [sendFollowRequestMutation] = useMutation(sendFollowRequest);
  const [unFollowRequestMutation] = useMutation(unFollowCreators);

  const creators = useMemo(
    () =>
      searchTerm
        ? searchedCreators?.getCreatorsQuery?.data || []
        : trendingCreators?.getTrendingCreators?.data || [],
    [searchTerm, searchedCreators, trendingCreators],
  );

  useEffect(() => {
    getCreators({ variables: { limit: 30 } });

    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [getCreators]);

  const handleSearchChange = useCallback(
    (e) => {
      const value = e.target.value;
      setSearchTerm(value);
      if (value) {
        searchCreatorsQuery({ variables: { searchTerm: value, limit: 30 } });
      }
    },
    [searchCreatorsQuery],
  );

  const handleFollowClick = useCallback(
    (receiverId) => {
      sendFollowRequestMutation({
        variables: { receiverId },
        onCompleted: () => {
          setFollowedCreators((prev) => ({
            ...prev,
            [receiverId]: true,
          }));
        },
      });
    },
    [sendFollowRequestMutation],
  );

  const handleUnFollowClick = useCallback(
    (shouldUnfollow, userId) => {
      if (shouldUnfollow && userId) {
        unFollowRequestMutation({
          variables: { receiverId: userId },
          onCompleted: () => {
            setFollowedCreators((prev) => {
              const updatedCreators = { ...prev };
              delete updatedCreators[userId];
              return updatedCreators;
            });
            searchCreatorsQuery({ variables: { searchTerm, limit: 30 } });
          },
        });
      }
      setShowUnfollowPopup(false);
    },
    [unFollowRequestMutation, searchTerm, searchCreatorsQuery],
  );

  const onFollowingStatus = useCallback(
    (status, item) => {
      if (status === "ACCEPTED") {
        setCurrentUser(item);
        setShowUnfollowPopup(true);
      } else {
        handleUnFollowClick(true, item.id);
      }
    },
    [handleUnFollowClick],
  );

  return (
    <div>
      {/* Search Bar */}
      <div className={classes.searchBarContainer}>
        <div className={classes.searchBar}>
          <TextField
            fullWidth
            placeholder="Search for Mikloset Users"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconComponent iconTitle="SearchLightIcon" />
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
                backgroundColor: "#e1d1c5",
                "& input::placeholder": {
                  color: "#000",
                  opacity: 1,
                  fontSize: "16px",
                  fontStyle: "italic",
                },
              },
            }}
          />
        </div>
      </div>

      <div className={classes.mainContainer}>
        {!searchTerm && (
          <div className={classes.titleOne}>
            <ArrowBackIcon onClick={() => navigate(-1)} style={{ cursor: "pointer" }} />
            <span>Trending Creators</span>
          </div>
        )}

        {/* Creators List */}
        <div className={searchTerm ? classes.verticalLayout : classes.gridLayout}>
          {creators.map((item, i) => (
            <div key={i} className={searchTerm ? classes.searchCreatorItem : classes.creatorItem}>
              <div className={classes.avatarWrapper}>
                <Avatar
                  alt={item.username}
                  src={item.profileUrl || ""}
                  sx={{
                    width: windowWidth <= mobileMaxWidth ? 36 : 70,
                    height: windowWidth <= mobileMaxWidth ? 36 : 70,
                  }}
                  onClick={() => navigate(`/followme/profile/${item.username}`)}
                />
              </div>
              {/* <p className={searchTerm ? classes.verticalCreatorName : classes.creatorName}>
                {item.name}
                {searchTerm && item.username}
              </p> */}
              <div className={classes.textWrapper}>
                <p className={searchTerm ? classes.verticalCreatorName : classes.creatorName}>
                  {item.name}
                </p>
                {searchTerm && <p className={classes.followingUsername}>{item.username}</p>}
              </div>
              <button
                className={classes.followButton}
                onClick={() => {
                  if (followedCreators[item.id] || item.isFollowed) {
                    onFollowingStatus(item.isFollowed, item);
                  } else {
                    handleFollowClick(item.id);
                  }
                }}
                style={{
                  backgroundColor:
                    followedCreators[item.id] || item.isFollowed ? "#363434" : "#335b46",
                }}
              >
                {followedCreators[item.id] || item.isFollowed === "PENDING"
                  ? "Requested"
                  : item.isFollowed === "ACCEPTED"
                  ? "Following"
                  : item.action || "Follow"}
              </button>
            </div>
          ))}
        </div>
      </div>
      {showUnfollowPopup && currentUser && (
        <ModalPortal>
          <Modal
            type="unFollowCreator"
            handleButtonClick={(value) => handleUnFollowClick(value, currentUser.id)}
            svgType="circle"
          />
        </ModalPortal>
      )}
    </div>
  );
}

import { useStyles } from "./styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Avatar } from "@mui/material";
import ProfileCurationLists from "../../../components/ProfileCurationLists";
import ModalPortal from "../../../containers/common/modalPortal";
import Modal from "../../../containers/common/modalPortal/modal";
import {
  getFollowersListsbyUsername,
  getFollowingListsbyUsername,
  getUserDetailsByUsername,
  getCurationsListByUsername,
  getItemsFromClosetByUsername,
  sendFollowRequest,
  unFollowCreators,
} from "../../../apollo/operations/followRequest";

import { ReactComponent as Arrow } from "../../../assets/svg/arrowRightLinear.svg";

export default function CreatorProfile() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { username } = useParams();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [user, setUser] = useState({});
  const [curationList, setCurationList] = useState([]);
  const [saleItems, setSaleItems] = useState([]);
  const [showUnfollowPopup, setShowUnfollowPopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [getCurations, { data: listOfCuration }] = useLazyQuery(getCurationsListByUsername);
  const [getSaleItems, { data: saleItemList }] = useLazyQuery(getItemsFromClosetByUsername);
  const [getFollowers, { data: followersLists }] = useLazyQuery(getFollowersListsbyUsername);
  const [getFollowings, { data: followingLists }] = useLazyQuery(getFollowingListsbyUsername);
  const [sendFollowRequestMutation] = useMutation(sendFollowRequest);
  const [unFollowRequestMutation] = useMutation(unFollowCreators);
  const [getProfile, { data: userProfile }] = useLazyQuery(getUserDetailsByUsername);

  useEffect(() => {
    getFollowers({ variables: { userName: username } });
    getFollowings({ variables: { userName: username } });
    getProfile({ variables: { userName: username } });
    getCurations({ variables: { userName: username } });
    getSaleItems({ variables: { category: [], userName: username } });

    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [username]);

  useEffect(() => {
    if (userProfile?.getUserDetailsByUsername?.statusCode === "200") {
      setUser(userProfile.getUserDetailsByUsername.data);
    }
  }, [userProfile]);

  useEffect(() => {
    if (saleItemList?.getItemsFromCloset?.length) {
      setSaleItems(saleItemList.getItemsFromCloset);
    }
  }, [saleItemList]);

  useEffect(() => {
    if (listOfCuration?.getCurationsList?.data?.length) {
      const filteredList = listOfCuration.getCurationsList.data.filter(
        (item) => item.isAdded && item.curationList.length,
      );
      setCurationList(filteredList);
    }
  }, [listOfCuration]);

  useEffect(() => {
    if (currentPage > Math.ceil(curationList.length / itemsPerPage)) {
      setCurrentPage(1);
    }
  }, [curationList]);

  const paginatedCurationList = curationList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  const handleFollowClick = (receiverId) => {
    sendFollowRequestMutation({ variables: { receiverId } });
  };

  const handleUnFollowClick = (confirm) => {
    if (confirm) {
      unFollowRequestMutation({ variables: { receiverId: user.id } });
    }
    setShowUnfollowPopup(false);
  };

  const onFollowingStatus = (status) => {
    if (status === "ACCEPTED") {
      setShowUnfollowPopup(true);
    } else {
      handleUnFollowClick(true);
    }
  };

  const handleOffSet = (direction) => {
    if (direction === "back" && currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
    if (direction === "next" && currentPage < Math.ceil(curationList.length / itemsPerPage)) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.titleOne}>
        <ArrowBackIcon onClick={() => navigate(-1)} style={{ cursor: "pointer" }} /> {username}
      </div>
      <div className={classes.profile}>
        <Avatar
          alt={user?.firstName}
          src={user?.profilePicUrl}
          sx={{
            width: windowWidth <= 768 ? 92 : 164,
            height: windowWidth <= 768 ? 92 : 164,
          }}
        />
        <div className={classes.profileDetails}>
          <h1>{`${user?.firstName} ${user?.lastName}`}</h1>
          <p className={classes.email}>{user?.email}</p>
          <button
            className={classes.Button}
            style={{ backgroundColor: user?.isFollowing ? "#363434" : "#335b46" }}
            onClick={() => {
              user?.isFollowing ? onFollowingStatus(user.isFollowing) : handleFollowClick(user?.id);
            }}
          >
            {user?.isFollowing === "ACCEPTED"
              ? "Following"
              : user?.isFollowing === "PENDING"
              ? "Requested"
              : user?.isFollowed
              ? "Follow Back"
              : "Follow"}
          </button>
        </div>
      </div>

      <div className={classes.followersDetails}>
        <div
          className={classes.subFollow}
          onClick={() => navigate(`/followme/${username}/followings`)}
        >
          <h1>{followingLists?.getFollowingLists?.data?.length || 0}</h1>Following
        </div>
        <div
          className={classes.subFollow}
          onClick={() => navigate(`/followme/${username}/followers`)}
        >
          <h1>{followersLists?.getFollowersLists?.data?.length || 0}</h1>Followers
        </div>
      </div>

      <div className={classes.about}>{user?.bio}</div>

      {paginatedCurationList.length > 0 && user?.isFollowing === "ACCEPTED" && (
        <>
          <p className={classes.following}>Curated by Me</p>
          <div className={classes.paginationControls}>
            <div className={classes.paggination}>
              <p>{`${curationList.length ? (currentPage - 1) * itemsPerPage + 1 : currentPage} - ${
                currentPage * itemsPerPage > curationList.length
                  ? curationList.length
                  : currentPage * itemsPerPage
              } of ${curationList.length}`}</p>
              <Arrow className={classes.arrowLeft} onClick={() => handleOffSet("back")} />
              <Arrow className={classes.arrowRight} onClick={() => handleOffSet("next")} />
            </div>
          </div>
          <div className={`${classes.curationContainer} ${classes.favCurationContainer}`}>
            {paginatedCurationList.map(
              (outfits, index) =>
                outfits.curation.length > 0 && (
                  <ProfileCurationLists outfits={outfits} key={index} index={index} />
                ),
            )}
          </div>
        </>
      )}

      {showUnfollowPopup && (
        <ModalPortal>
          <Modal
            type={"unFollowCreator"}
            handleButtonClick={handleUnFollowClick}
            svgType="circle"
          />
        </ModalPortal>
      )}
    </div>
  );
}

import { useStyles } from "./styles";
import { ReactComponent as Arrow } from "../../../src/assets/svg/arrowRightLinear.svg";
import { useState, useEffect, useCallback } from "react";

export default function Pagination({ curationsData: curationsDataLength, sliderRef, slide }) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [internalPage, setInternalPage] = useState(0);

  // Responsive breakpoints for pagination size
  const responsive = [
    { viewWidth: window.matchMedia("(max-width: 480px)").matches, size: 1 },
    { viewWidth: window.matchMedia("(min-width: 481px) and (max-width: 767px)").matches, size: 1 },
    { viewWidth: window.matchMedia("(min-width: 768px) and (max-width: 1024px)").matches, size: 2 },
    { viewWidth: window.matchMedia("(min-width: 1025px) and (max-width: 1299px)").matches, size: 2 },
    { viewWidth: window.matchMedia("(min-width: 1300px)").matches, size: 3 },
  ];

  // Determine page size based on screen width
  const pageSize = responsive.find((viewSize) => viewSize.viewWidth)?.size || 1;

  // Function to handle pagination offset (next/prev)
  const handleOffset = useCallback(
    (value) => {
      if (!sliderRef?.current) return;

      if (value === "back" && page > 0) {
        const newPage = page - 1;
        setPage(newPage);
        setInternalPage(newPage);
        sliderRef.current.slickGoTo(newPage * pageSize);
      } else if (value === "next" && curationsDataLength > (page + 1) * pageSize) {
        const newPage = page + 1;
        setPage(newPage);
        setInternalPage(newPage);
        sliderRef.current.slickGoTo(newPage * pageSize);
      }
    },
    [page, pageSize, curationsDataLength, sliderRef]
  );

  // Update page number based on external slide changes
  useEffect(() => {
    if (internalPage !== page) {
      setPage(Math.floor(slide / pageSize));
    }
  }, [slide, pageSize, internalPage]);

  // Attach event listeners to Slick slider arrows
  useEffect(() => {
    const nextButton = document.querySelector(".slick-next");
    const prevButton = document.querySelector(".slick-prev");

    if (!nextButton || !prevButton) return;

    const handleNextClick = () => handleOffset("next");
    const handlePrevClick = () => handleOffset("back");

    nextButton.addEventListener("click", handleNextClick);
    prevButton.addEventListener("click", handlePrevClick);

    return () => {
      nextButton.removeEventListener("click", handleNextClick);
      prevButton.removeEventListener("click", handlePrevClick);
    };
  }, [handleOffset]);

  // Update slick-track CSS custom properties dynamically for pseudo-elements
  useEffect(() => {
    const slickTrack = document.querySelector(".slick-track");
    if (slickTrack) {
      // Set dynamic values for pseudo-element styles using CSS variables
      slickTrack.style.setProperty("--page-before", `"Page ${page + 1}"`);
      slickTrack.style.setProperty("--page-after", `"of ${Math.ceil(curationsDataLength / pageSize)}`);
      slickTrack.style.setProperty("--page-font-size", pageSize > 2 ? "16px" : "14px");
    }
  }, [page, curationsDataLength, pageSize]);

  useEffect(() => {
    const slickSlides = document.querySelectorAll(".slick-slide");
    if (slickSlides.length === 0) return;

    const observer = new MutationObserver(() => {
      const activeSlide = document.querySelector(".slick-slide.slick-active.slick-current");
      if (activeSlide) {
        // Find the new active page based on the current active slide
        const newPage = Math.floor([...slickSlides].indexOf(activeSlide) / pageSize);

        // Update internalPage only if the active slide page changes
        if (newPage !== internalPage) {
          setInternalPage(newPage);
        }

        // Only update page if internalPage changes
        if (newPage !== page) {
          setPage(newPage);
        }
      }
    });

    // Start observing each slick-slide
    slickSlides.forEach((slideElement) => {
      observer.observe(slideElement, {
        attributes: true,
        childList: false,
        subtree: false,
      });
    });

    return () => observer.disconnect();
  }, [pageSize, page, internalPage]);

  return (
    <div className={classes.paggination} style={{ fontFamily: "var(--secondaryHeaderFont)" }}>
      <p>
        {curationsDataLength > 0
          ? `${page * pageSize + 1} - ${(page + 1) * pageSize > curationsDataLength
            ? curationsDataLength
            : (page + 1) * pageSize
          } of ${curationsDataLength}`
          : "No data available"}
      </p>
      <Arrow className={classes.arrowLeft} onClick={() => handleOffset("back")} />
      <Arrow className={classes.arrowRight} onClick={() => handleOffset("next")} />
    </div>
  );
}
